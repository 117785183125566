var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"urban_renewal-tables","fluid":"","tag":"section"}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-clipboard-text","title":_vm.$t('common_part.title')}},[_c('v-col',{staticClass:"text-right"},[_c('dialog-message',{attrs:{"dialog_message":_vm.dialog_message,"message":_vm.message}}),_c('v-btn',{staticClass:"mx-2",attrs:{"color":"aqua"},on:{"click":function($event){return _vm.back()}}},[_vm._v(_vm._s(_vm.$t("common.back")))]),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.createCommonPart()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil-plus")]),_vm._v(_vm._s(_vm.$t("common_part.createCommonPart"))+" ")],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalItems,"footer-props":_vm.footerProps,"multi-sort":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.building_no",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('div',[_vm._v(" "+_vm._s(item.region.city.name)+_vm._s(item.region.district.name)+_vm._s(item.region.name)+" ")]),_c('div',[_vm._v(" "+_vm._s(item.building_no.split("_").pop())+" ")])])]}},{key:"item.building_area",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_vm._v(" "+_vm._s(_vm.areaFormat(item.building_area))+" ")])]}},{key:"item.building_info",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('div',[_vm._v(" "+_vm._s(item.building_info.region.city.name)+_vm._s(item.building_info.region.district.name)+_vm._s(item.building_info.region.name)+" ")]),_c('div',[_vm._v(" "+_vm._s(item.building_info.building_no.split("_").pop())+" ")])])]}},{key:"item.building_numerator",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('span',[_vm._v(" "+_vm._s(item.building_numerator)+"/"+_vm._s(item.building_denominator)+" ")])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-btn',{staticClass:"mx-2",attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.showDetail(item)}}},[_vm._v(_vm._s(_vm.$t("common.profile")))]),_c('v-btn',{staticClass:"mx-2",attrs:{"color":"red","small":""},on:{"click":function($event){return _vm.RemoveCommonPart(item)}}},[_vm._v(_vm._s(_vm.$t("common.delete")))])],1)]}},{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"dense":"","show-first-last-page":_vm.footerProps.showFirstLastPage,"show-current-page":_vm.footerProps.showCurrentPage,"first-icon":_vm.footerProps.firstIcon,"last-icon":_vm.footerProps.lastIcon,"prev-icon":_vm.footerProps.prevIcon,"next-icon":_vm.footerProps.nextIcon,"pagination":pagination,"options":options,"itemsPerPageOptions":_vm.footerProps.itemsPerPageOptions},on:{"update:options":updateOptions}})]}}])},[_c('template',{slot:"no-data"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('common.noData'))+" ")])])],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }