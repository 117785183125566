<template>
  <v-container id="urban_renewal-tables" fluid tag="section">
    <base-material-card
      icon="mdi-clipboard-text"
      :title="$t('common_part.title')"
      class="px-5 py-3"
    >
      <v-col class="text-right">
        <dialog-message
          :dialog_message="dialog_message"
          :message="message"
        ></dialog-message>
        <v-btn color="aqua" class="mx-2" @click="back()">{{
          $t("common.back")
        }}</v-btn>
        <v-btn color="primary" class="mb-2" @click="createCommonPart()">
          <v-icon left>mdi-pencil-plus</v-icon
          >{{ $t("common_part.createCommonPart") }}
        </v-btn>
      </v-col>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalItems"
        :footer-props="footerProps"
        multi-sort
      >
        <template v-slot:item.building_no="{ item }">
          <v-flex>
            <div>
              {{ item.region.city.name}}{{ item.region.district.name }}{{ item.region.name }}
            </div>
            <div>
              {{ item.building_no.split("_").pop() }}
            </div>
          </v-flex>
        </template>
        <template v-slot:item.building_area="{ item }">
          <v-flex>
            {{ areaFormat(item.building_area) }}
          </v-flex>
        </template>
        <template v-slot:item.building_info="{ item }">
          <v-flex>
            <div>
              {{
                item.building_info.region.city.name
              }}{{ item.building_info.region.district.name
              }}{{ item.building_info.region.name }}
            </div>
            <div>
              {{ item.building_info.building_no.split("_").pop() }}
            </div>
          </v-flex>
        </template>
        <template v-slot:item.building_numerator="{ item }">
          <v-flex>
            <span>
              {{ item.building_numerator}}/{{ item.building_denominator }}
            </span>
          </v-flex>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-flex>
            <v-btn
              color="primary"
              class="mx-2"
              small
              @click="showDetail(item)"
              >{{ $t("common.profile") }}</v-btn
            >
            <v-btn
              color="red"
              class="mx-2"
              small
              @click="RemoveCommonPart(item)"
              >{{ $t("common.delete") }}</v-btn
            >
          </v-flex>
        </template>
        <template v-slot:top="{ pagination, options, updateOptions }">
          <v-data-footer
            dense
            :show-first-last-page="footerProps.showFirstLastPage"
            :show-current-page="footerProps.showCurrentPage"
            :first-icon="footerProps.firstIcon"
            :last-icon="footerProps.lastIcon"
            :prev-icon="footerProps.prevIcon"
            :next-icon="footerProps.nextIcon"
            :pagination="pagination"
            :options="options"
            @update:options="updateOptions"
            :itemsPerPageOptions="footerProps.itemsPerPageOptions"
          />
        </template>
        <template slot="no-data">
          <div>
            {{ $t('common.noData') }}
          </div>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import CommonUtil from "@/utils/commonutil.js";
export default {
  data: (vm) => ({
    dialog_message: false,
    loading: false,
    items: [],
    message: {
      title: "",
      text: "",
    },
    headers: [
      {
        sortable: true,
        text: vm.$i18n.t("common_part.building_no"),
        value: "building_no",
      },
      {
        sortable: true,
        text: vm.$i18n.t("common_part.building_area"),
        value: "building_area",
      },
      {
        sortable: true,
        text: vm.$i18n.t("common_part.building_info"),
        value: "building_info",
      },
      {
        sortable: false,
        text: vm.$i18n.t("common_part.building_rate"),
        value: "building_numerator",
      },
      {
        sortable: false,
        // text: vm.$i18n.t("common.actions"),
        text: '',
        value: "actions",
      },
    ],
    options: {},
    totalItems: 0,
    footerProps: {
      showFirstLastPage: true,
      showCurrentPage: true,
      firstIcon: "mdi-chevron-double-left",
      lastIcon: "mdi-chevron-double-right",
      prevIcon: "mdi-chevron-left",
      nextIcon: "mdi-chevron-right",
      itemsPerPageOptions: [5, 10, 20],
    },
  }),
  computed: {
    ...mapState(["token"]),
  },
  methods: {
    ...mapMutations(["setTitle"]),
    getApi() {
      this.loading = true;
      const currentObj = this;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url =
        process.env.VUE_APP_SERVER_URL +
        "/backend/commpnPart/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token,
        },
        params: {
            page: page,
            page_size: itemsPerPage,
            urban_renewal: this.urban
        },
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          currentObj.loading = false;
          currentObj.setResult(response.data);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("get-api-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    setResult(data) {
      let info = JSON.parse(JSON.stringify(data));
      this.totalItems = data.count;
      this.items = info.results;
    },
    showDetail(item) {
      this.$router.push({
        name: "CommonPartDetail",
        query: {
          urban: this.urban,
          item: item.id,
        },
      });
    },
    createCommonPart() {
      this.$router.push({
        name: "CommonPartDetail",
        query: {
          urban: this.urban,
          item: null,
        },
      });
    },
    RemoveCommonPart(item) {
      if (
        !confirm(
          this.$i18n.t("common.confirm") + this.$i18n.t("common.delete") + "？"
        )
      ) {
        return;
      }
      const currentObj = this;
      const url =
        process.env.VUE_APP_SERVER_URL +
        "/backend/commpnPart/" +
        item.id +
        "/remove/";
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token,
        },
      };
      this.axios
        .patch(url, {}, config)
        .then(function (response) {
          alert("刪除成功。");
          currentObj.getApi();
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("remove-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    back(item) {
      this.$router.push({
        name: "UrbanRenewalTables",
        params: {
          // item: item
        },
      });
    },
    areaFormat(area){
      return CommonUtil.areaFormat(area)
    }
  },
  watch: {
    options: {
      handler() {
        this.getApi();
      },
    },
  },
  mounted() {
    this.setTitle(this.$i18n.t("title.CommonPart"));
    this.urban = this.$route.query.item;
    // this.getApi();
  },
};
</script>