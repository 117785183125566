var CommonUtil = {
    getIndexText(index) {
        let number_str = [
            "一",
            "二",
            "三",
            "四",
            "五",
            "六",
            "七",
            "八",
            "九",
            "十"
        ]
        let tens_number = Math.floor(index / 10)
        let digits_number = Math.floor(index % 10)
        let index_text = "";
        if (tens_number == 0) {
            index_text = number_str[digits_number - 1]
        } else if (tens_number == 1) {
            index_text = number_str[number_str.length - 1]
            if (digits_number != 0) {
                index_text += number_str[digits_number - 1]
            }
        } else {
            index_text = number_str[tens_number - 1] + number_str[number_str.length - 1]
            if (digits_number != 0) {
                index_text += number_str[digits_number - 1]
            }
        }
        index_text += "、"
        return index_text
    },
    getDateString(){
        let today = new Date();
        let year = today.getFullYear();
        let month = today.getMonth();
        let date = today.getDate();
        let date_str = year.toString();
        if (month < 9){
            date_str += "0" + (month+1).toString()
        } else {
            date_str += (month+1).toString()
        }
        if (date < 9){
            date_str += "0" + (date+1).toString()
        } else {
            date_str += (date+1).toString()
        }
        return date_str
    },
    areaFormat(item){
        if(typeof item == 'string'){
            return parseFloat(item).toFixed(2)
        } else if(typeof item == 'float') {
            return item.toFixed(2)
        } else {
            return ""
        }
    }
}


export default CommonUtil;